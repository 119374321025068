<template>
    <div>
        <el-card class="h-100" style="max-width: 480px; border-radius: 20px" shadow="never">
            <el-form ref="form" :model="formData" label-width="auto" style="max-width: 480px">
                <el-form-item prop="model_id">
                    <el-select-v2 v-model="formData.model_id" :options="mobileList" :props="selectProps"
                        :placeholder="$t('home.lenders.toolBox.select.mobile')" size="large" />
                </el-form-item>
                <el-form-item prop="battery_health">
                    <div style="display: flex; flex-direction: row;width: 100%;align-items: center">
                        <el-input type="text" :min="1" :max="100" v-model="formData.battery_health"
                            :placeholder="$t('home.lenders.toolBox.input.battery_health')" @input="handleInput" />
                        <PopoverBatteryCheck></PopoverBatteryCheck>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button color="#07309e" style="width: 100%;height: 40px;border-radius: 10px;" type="primary"
                        @click="onSubmit">{{
                            $t("home.lenders.toolBox.button.calc") }}</el-button>
                </el-form-item>
            </el-form>
            <div class="hr my-4"></div>
            <div class="bottom">
                <template v-if="!calcResult">
                    <div class="text-center loan-summary-none mt-3">
                        <div class="mb-4">
                            <img src="@/assets/images/icon/loan-summary.webp" style="width: 30%;">
                        </div>
                        <h5>{{ $t("home.lenders.toolBox.loanSummary") }}</h5>
                        <p class="px-5 caption">{{ $t("home.lenders.toolBox.loanText") }}</p>
                    </div>
                </template>
                <template v-else>
                    <div class="loan-summary-none summary-box">
                        <h5 style="text-align: left; vertical-align:text-top;font-size: 1.25rem;font-weight: 500;">{{
                            $t("home.lenders.toolBox.loanSummary") }}
                        </h5>
                        <el-row class="row-item">
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.iPhoneValue') }}</el-col>
                            <el-col :span="12" class="col-item">RM{{ calcResult.phone_value }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.BatteryHealth') }} ({{ calcResult.battery_health
                                }}%)</el-col>
                            <el-col :span="12" class="col-item text-danger">-RM{{ calcResult.discount_price }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{ $t('home.lenders.toolBox.query.LoanAmount')
                                }}</el-col>
                            <el-col :span="12">RM{{ calcResult.loan_amount }}</el-col>
                        </el-row>
                        <el-row class="row-item">
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.LoanTenure4Weeks') }}</el-col>
                            <el-col :span="12" class="col-item">{{ calcResult.tenures[0].interest + '% '+
                                $t('home.lenders.toolBox.query.Interest') }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.WeeklyRepaymentAmount') }}</el-col>
                            <el-col :span="12" class="col-item">RM{{ calcResult.tenures[0].weekly_payment + '/' +
                                $t('home.lenders.toolBox.query.week')
                                }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.FinalCashfor4WeeksLoan') }}</el-col>
                            <el-col :span="12" class="col-item">{{ `RM${calcResult.phone_value} -
                                ${calcResult.tenures[0].interest}% = RM${calcResult.tenures[0].final_cash_amount}`
                                }}</el-col>
                        </el-row>
                        <el-row class="row-item">
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.LoanTenure8Weeks') }}</el-col>
                            <el-col :span="12" class="col-item">{{ calcResult.tenures[1].interest + '% '+
                                $t('home.lenders.toolBox.query.Interest') }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.WeeklyRepaymentAmount') }}</el-col>
                            <el-col :span="12" class="col-item">RM{{ calcResult.tenures[1].weekly_payment + '/' +  $t('home.lenders.toolBox.query.week')
                                }}</el-col>
                            <el-col :span="12" class="text-gray col-item">{{
                                $t('home.lenders.toolBox.query.FinalCashfor8WeeksLoan') }}</el-col>
                            <el-col :span="12" class="col-item">{{ `RM${calcResult.phone_value} -
                                ${calcResult.tenures[1].interest}% = RM${calcResult.tenures[1].final_cash_amount}`
                                }}</el-col>
                        </el-row>
                    </div>
                </template>
            </div>
        </el-card>
    </div>
</template>

<script>

import { calcLender } from "@/api/mobile";
import { mapState, mapMutations } from 'vuex'
import PopoverBatteryCheck from "../form/PopoverBatteryCheck.vue";

export default {
    components:{
        PopoverBatteryCheck
    },
    data() {
        return {
            formData: {
                model_id: '',
                battery_health: "",
            },

            options: [],
            calcResult: null,
            selectProps: {
                value: "id",
                label: "name"
            }
        }
    },
    computed: {
        ...mapState("app", ["mobileList"]),
        rules() {
            return {
                model_id: [
                    {
                        required: true,
                        message: '请选择手机型号',
                        trigger: 'change',
                    }
                ],
                battery_health: [
                    { required: true, message: '请输入电池健康值', trigger: 'blur' }
                ]
            }
        }
    },
    created() {
    },
    methods: {
        loadData() {
            mobile_list().then(res => {
                console.log("loadData:", res)
                if (res.code == 0) {
                    this.mobileModelList = res.data
                }
            })
        },
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    calcLender(this.formData).then(res => {
                        console.log("calcLender res:", res)
                        if (res.code == 0) {
                            this.calcResult = res.data
                        }
                    })
                } else {
                    console.log('error submit!')
                }
            })
        },
        handleInput(value) {
            // 使用正则表达式来判断是否为正整数
            value = value.replace(/[^\d]/g, '')

            const regex = /^([1-9]|[1-9]\d|100)?$/;
            if (!regex.test(value)) {
                // 如果输入不符合规定的数字范围，则将输入值重置为上一个合法的值
                value = value.substring(0, value.length - 1);
            }
            this.formData.battery_health = value

        }

    }
}
</script>

<style lang="scss" scoped>
.h-100 {
    height: 100%;
    // color:#07309e;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.bottom {
    // text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // height: 100%;
}

.text-center {
    text-align: center !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.hr {
    border-bottom: 1px solid #F5F5F5;
}

.summary-box {
    font-size: 12px;
    text-align: left;

    .text-gray {
        color: #a0a0a0;
    }

    .text-danger {
        --bs-text-opacity: 1;
        color: #dc3545;
    }

    .row-item {
        margin-top: 20px;
    }

    .col-item {
        margin-top: 5px;
    }
}

</style>