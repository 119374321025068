
import en from './en.json'
import zh from './zh.json'
import th from './th.json'
import vi from './vi.json'
import id from './id.json'
import ph from './ph.json'

export default {
  "zh-cn":zh,
  th,
  vi,
  id,
  ph,
  "my":en,
  "sg": en
}
