<template>
    <div class="loan-row pb-5 pt-4 pt-lg-5 px-3 px-lg-0 my-lg-4">
        <div class="container-wrapper">
            <h4 class="mb-3">{{ $t("home.lenders.toolBox.title") }}</h4>
            <el-row :gutter="10">
                <el-col :span="8" :xs="24">
                    <Toolbox></Toolbox>
                </el-col>
                <el-col :span="16" :xs="24">
                    <div style="height: 100%;">
                        <el-row :gutter="10">
                            <el-col class="pdt-1" :span="12" :xs="24">
                                <div class="card card-bg1 p-5">
                                    <h4>{{ $t("home.lenders.steps.LoanApplicationSteps") }}</h4>
                                    <el-button class="btn outline-btn">{{
                                        $t("home.lenders.steps.button.findOutMore") }}</el-button>
                                </div>
                            </el-col>
                            <el-col class="pdt-1" :span="12" :xs="24">
                                <div class="card card-bg2 p-5">
                                    <h4>{{ $t("home.lenders.steps.LoanRepaymentSteps") }}</h4>
                                    <el-button class="btn outline-btn">{{
                                        $t("home.lenders.steps.button.findOutMore") }}</el-button>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col class="py-3" :span="24">
                                <div class="bottom-card card-bg3">
                                    <div>
                                        <h4>{{ $t("home.lenders.steps.TermsConditions") }}</h4>
                                        <p>{{ $t("home.lenders.steps.TermsConditionsDesc1") }}<br>{{
                                            $t("home.lenders.steps.TermsConditionsDesc2") }}</p>
                                        <el-button @click="clickReadMore"
                                            style="height: 40px;color:#07309e; background: #fff" plain>{{
                                                $t("home.lenders.steps.button.readMore") }}</el-button>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="applicant-row">
            <div class="container-wrapper my-lg-4">
                <h4 class="text-white mb-0">{{ $t("home.applicantes.title") }}</h4>
                <div>
                    <el-row :gutter="24">
                        <template v-for="(item, index) in ApplicantCardList" :key="index">
                            <el-col class="py-3" :span="8" :xs="24">
                                <ApplicantCard :applicantTotal="item.applicantTotal" :loanTotal="item.loanTotal"
                                    :currencyCode="item.currencyCode" :img="item.img"></ApplicantCard>
                            </el-col>
                        </template>
                    </el-row>
                </div>
            </div>
        </div>
        <TnrCard @clickBtn="clickTnrCard"></TnrCard>
        <GlobalDialog ref="globalDialog"></GlobalDialog>
    </div>
</template>

<script>
import Toolbox from "./ToolBox.vue"
import ApplicantCard from "./ApplicantCard.vue";
import TnrCard from "./TnrCard.vue";
import GlobalDialog from "../GlobalDialog.vue";

import { mapGetters, mapState } from 'vuex'

export default {
    components: {
        Toolbox,
        ApplicantCard,
        TnrCard,
        GlobalDialog
    },
    computed: {
        ...mapGetters("user", ["hasLogin"]),
    },
    data() {
        return {
            ApplicantCardList: [{
                applicantTotal: 629756,
                loanTotal: 35598149665,
                currencyCode: "VND",
                img: require('@/assets/images/flags/flag-vietnam.webp')
            },
            {
                applicantTotal: 633116,
                loanTotal: 32719012,
                currencyCode: "RM",
                img: require('@/assets/images/flags/flag-malaysia.webp')
            },
            {
                applicantTotal: 629104,
                loanTotal: 126277941,
                currencyCode: "THB",
                img: require('@/assets/images/flags/flag-thailand.webp')
            },
            {
                applicantTotal: 625186,
                loanTotal: 40069954358,
                currencyCode: "IDR",
                img: require('@/assets/images/flags/flag-indonesia.webp')
            },
            {
                applicantTotal: 629783,
                loanTotal: 238949955,
                currencyCode: "PHP",
                img: require('@/assets/images/flags/flag-philippine.webp')
            },
            {
                applicantTotal: 621179,
                loanTotal: 12455162,
                currencyCode: "SGD",
                img: require('@/assets/images/flags/flag-singapore.webp')
            }]
        }
    },
    methods: {
        clickTnrCard() {
            if (!this.hasLogin) {
                this.$refs.globalDialog.show("login")
                return
            } else {
                this.$router.push("/InstantApply")
            }
        },
        clickReadMore() {
            this.$router.push({ path: "/info", query: { tab: 3 }})
        }
    },
}
</script>

<style lang="scss" scoped>
@import "./style.scss";

@media (min-width: 992px) {
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
}

@media (min-width: 992px) {
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
}

@media (min-width: 992px) {
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.container-wrapper {
    // max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.mb-3 {
    margin-bottom: 1rem !important;
    text-align: start;
}

.h4,
h4 {
    font-size: calc(1.275rem + .3vw);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color);
}

.card {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    flex-direction: column;
    padding: 4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bottom-card {
    color: #fff;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    padding: 4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.card-bg1 {
    background-image: url('@/assets/images/card-bg1.webp');

}

.card-bg2 {
    background-image: url('@/assets/images/card-bg2.webp');
    // height: 300px;
}

.card-bg3 {
    background-image: url('@/assets/images/card-bg3.webp');
    // height: 300px;
}

.btn {
    background: transparent;
    padding: 8px 15px;
    height: 40px;
    border-radius: 10px !important;
    color: #fff !important;
    font-size: 14px !important;
    text-transform: capitalize;
    font-weight: 500 !important;
    white-space: nowrap;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: .3s !important;
    border: none;
    width: 70%;


    &:hover {
        background: transparent;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
}

.btn.outline-btn {
    border: 2px solid #fff !important;
}

.mt-4 {
    margin-top: 1.5rem;
}

@media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
}


.top-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    // justify-content: space-between;
}

.applicant-row {
    background-image: url('@/assets/images/applicant-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 5rem;
    margin-bottom: 5rem;
    // width: 100%;
    // height: 100%;
    padding: 3rem 1rem;


    @media only screen and (max-width: 991px) {
        .container-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .container-wrapper {
        max-width: 1200px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
    }

    .text-white {
        color: #fff;
    }

    .mb-0 {
        margin-bottom: 1.5rem;
    }
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.pdt-1 {
    padding-bottom: 1rem;
}
</style>