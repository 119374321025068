<template>
  <div class="navbar">
    <div class="hamburger-container">
      <div class="img-box" @click="clickLogo">
        <img style="width: 159px; height: 25px" src="@/assets/logo.webp" alt="logo" />
      </div>
    </div>
    <div class="right-menu">
      <el-space class="nav-box">
        <div v-if="!hasLogin" class="hide-locale">
          <el-space>
            <template v-for="(flag, index) in flags" :key="index">
              <div v-if="flag.isMenu" @click="clickFlag(index)" class="nav-item"
                :class="currentFlagIndex == index ? 'active' : ''">
                <span> {{ flag.name }}</span>
              </div>
            </template>
            <el-button @click="showGlobalDialog('register')" type="primary">{{ $t("navbar.button.register")
              }}</el-button>
            <el-button @click="showGlobalDialog('login')" class="btn outline-btn">{{ $t("navbar.button.login")
              }}</el-button>
          </el-space>
        </div>
        <div v-else class="hide-locale">
          <el-space>
            <template v-for="(item, index) in menuItems" :key="index">
              <div class="nav-item" @click="clickMenu(index)" :class="currentMenuIndex == index ? 'active' : ''">
                <span>{{ item.name }}</span>
              </div>
            </template>
            <el-button @click="logout" type="primary">{{ $t("navbar.button.logout") }}</el-button>
          </el-space>
        </div>
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" @command="clickFlag">
          <div class="avatar-wrapper">
            <img :src="currentFlag.img" class="user-avatar">
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="(flag, index) in flags" :key="index" :command="index">
                <span :class="currentFlagIndex == index ? 'active-drop-item' : ''">{{ flag.code }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <div class="user-profile" v-if="hasLogin">
          <RouterLink to="/profile" class="border-box">
            <el-icon size="20px" color="#fff">
              <UserFilled />
            </el-icon>
          </RouterLink>
        </div>
        <div class="show-menu-btn">
          <button @click="drawer = true" class="reset-btn menu-hamburger hamburger">
            <span class="hamburger-1"></span>
            <span class="hamburger-2"></span>
            <span class="hamburger-3"></span>
          </button>
        </div>
      </el-space>

    </div>
    <GlobalDialog ref="globalDialog"></GlobalDialog>
    <el-drawer v-model="drawer" size="70%" style="background-color:#00309e">
      <template #header>
        <el-image style="height: 20px;width: auto;" fit="contain" :src="require('@/assets/logo.webp')"></el-image>
      </template>
      <template #default>
        <div>
          <div v-if="!hasLogin">
            <el-space>
              <el-button @click="showGlobalDialog('register')" type="primary">{{ $t("navbar.button.register")
                }}</el-button>
              <el-button @click="showGlobalDialog('login')" class="login-btn">{{ $t("navbar.button.login")
                }}</el-button>
            </el-space>
            <div>
              <template v-for="(flag, index) in flags" :key="index">
                <div v-if="flag.isMenu" @click="clickFlag(index)" class="nav-item"
                  :class="currentFlagIndex == index ? 'active' : ''">
                  <span> {{ flag.name }}</span>
                </div>
              </template>
            </div>
          </div>
          <div v-else>
            <el-button style="width: 100%;" @click="logout" type="primary">{{ $t("navbar.button.logout") }}</el-button>
            <template v-for="(item, index) in menuItems" :key="index">
              <div class="nav-item" @click="clickMenu(index)" :class="currentMenuIndex == index ? 'active' : ''">
                <span>{{ item.name }}</span>
              </div>
            </template>
          </div>
        </div>
      </template>
    </el-drawer>
    <div class="bottom-tab show-menu-btn">
      <div class="tabBar">
        <template v-if="hasLogin">
          <div class="tab-item bg-blue" v-for="(item, index) in bottomTabs" :key="index" @click="clcikTabbar(item.path)">
            {{ item.name }}
          </div>
        </template>
        <template v-else>
          <div class="no-tab-item" :class="item.bg" v-for="(item, index) in noLoginBottomTabs" :key="index" @click="showGlobalDialog(item.path)">
            {{ item.name }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalDialog from '@/components/GlobalDialog.vue';
import { mapGetters, mapState, mapMutations } from 'vuex'
import { mobile_list } from "@/api/mobile";
import { ElLoading } from 'element-plus'

export default {
  components: {
    GlobalDialog
  },
  data() {
    return {
      currentFlagIndex: 0,
      currentMenuIndex: -1,
      drawer: false
    }
  },
  computed: {
    ...mapGetters("user", ["hasLogin"]),
    ...mapState("app", ["locale"]),
    currentFlag() {
      return this.flags[this.currentFlagIndex]
    },
    flags() {
      return [
        {
          code: "MY",
          name: this.$t("navbar.language.my"),
          img: require("@/assets/images/flags/flag-malaysia.webp"),
          locale: "my",
          isMenu: true
        },
        {
          code: "PH",
          name: this.$t("navbar.language.ph"),
          img: require("@/assets/images/flags/flag-philippine.webp"),
          locale: "ph",
          isMenu: true
        },
        {
          code: "SG",
          name: this.$t("navbar.language.sg"),
          img: require("@/assets/images/flags/flag-singapore.webp"),
          locale: "sg",
          isMenu: true
        },
        {
          code: "TH",
          name: this.$t("navbar.language.th"),
          img: require("@/assets/images/flags/flag-thailand.webp"),
          locale: "th",
          isMenu: true
        },
        {
          code: "VN",
          name: this.$t("navbar.language.vn"),
          img: require("@/assets/images/flags/flag-vietnam.webp"),
          locale: "vi",
          isMenu: true
        },
        {
          code: "ID",
          name: this.$t("navbar.language.id"),
          img: require("@/assets/images/flags/flag-indonesia.webp"),
          locale: "id",
          isMenu: true
        },
        {
          code: "CN",
          img: require("@/assets/images/flags/flag-cn.webp"),
          locale: "zh-cn",
          isMenu: false
        },
      ]
    },
    menuItems() {
      return [{
        name: this.$t("navbar.menus.aboutus"),
        path: "/info"
      },
      {
        name: this.$t("navbar.menus.LoanRepayment"),
        path: "/LoanRepayment"
      },
      // {
      //   name: this.$t("navbar.menus.ReferralCommission"),
      //   path: ""
      // },
      {
        name: this.$t("navbar.menus.InstantApply"),
        path: "/InstantApply"
      },
      ]
    },
    bottomTabs() {
      return [
        {
          name: this.$t("navbar.bottomTab.loan"),
          path: "/LoanRepayment",
        },
        {
          name: this.$t("navbar.bottomTab.apply"),
          path: "/InstantApply"
        }
      ]
    },
    noLoginBottomTabs() {
      return [
        {
          name: this.$t("navbar.button.login"),
          path:"login",
          bg:"bg-blue"
        },
        {
          name: this.$t("navbar.button.register"),
          path:"register" ,
          bg:"bg-register"
        }
      ]
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations("app", ["setLocale", "setMobileList"]),
    loadData() {
      console.log("loadData, this.locale:", this.locale)
      // this.$loading(options)
      this.setMobileList([])
      const loadingInstance = ElLoading.service({ fullscreen: true })
      mobile_list({ currency_code: this.locale }).then(res => {
        console.log("loadData:", res)
        if (res.code == 0) {
          this.setMobileList(res.data)
        }
      }).finally(() => {
        loadingInstance.close()
      })
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.drawer = false
      await this.$store.dispatch('user/logout')
      this.$router.push('/')
    },
    clickFlag(index) {
      console.log("clickFlag index:", index)
      if (this.currentFlagIndex == index) return;
      this.drawer = false
      this.currentFlagIndex = index;
      let flagItem = this.flags[this.currentFlagIndex];
      console.log("flagItem:", flagItem)
      this.$nextTick(() => {
        this.$i18n.locale = flagItem.locale
        this.setLocale(flagItem.locale)
        this.loadData()
      })

    },
    showGlobalDialog(type) {
      this.drawer = false
      this.$nextTick(() => {
        this.$refs.globalDialog.show(type)
      })
    },
    clickMenu(index) {
      if (this.currentMenuIndex == index) return;
      this.drawer = false
      this.currentMenuIndex = index;
      let menuItem = this.menuItems[this.currentMenuIndex];
      this.$router.replace(menuItem.path)
    },
    clickLogo() {
      this.$router.replace("/")
      this.currentMenuIndex = -1
    },
    clcikTabbar(path) {
      this.$router.replace(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 70px;
  overflow: hidden;
  position: relative;
  background: #07309E;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
  z-index: 10;

  .hamburger-container {
    line-height: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;
    // margin-right: 20px;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }

    .img-box {
      display: flex;
      align-items: center;
    }

  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .hover-effect {
      cursor: pointer;
      transition: background .3s;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }


    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;
    }

    .avatar-container {
      // margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }

  .nav-item {
    color: #fff;
    text-transform: capitalize;
    // padding: 3px 0;
    height: 70px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    transition: .3s;
    cursor: pointer;

    &:hover::after {
      background-color: #00b2ff;
      transition: .3s;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 3px;
      background-color: transparent;
      border-radius: 10px;
      transition: .3s;
    }
  }

  .active::after {
    background-color: #00b2ff;
    transition: .3s;
  }

  .register-btn {
    background: #00B2FF;
  }
}

.nav-box {
  margin-right: 30px;
}

.user-profile {
  .border-box {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px #fff solid;
    border-radius: 100%;
  }

}

.active-drop-item {
  color: rgb(90, 118, 192);
}

.btn {
  background: transparent;
  padding: 8px 15px;
  // height: 40px;
  border-radius: 10px !important;
  color: #fff !important;
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: 500 !important;
  white-space: nowrap;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: .3s !important;
  border: none;
  // width: 80px;


  &:hover {
    background: transparent;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}

.btn.outline-btn {
  border: 2px solid #fff !important;
}

.menu-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.show-menu-btn {
  display: none;
}

@media (max-width: 992px) {
  .show-menu-btn {
    display: flex;
  }

  .hide-locale {
    display: none !important;
  }

  .nav-box {
    margin-right: 0px;
  }
}

.hide-locale {
  display: flex;
}

.menu-hamburger {
  width: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  height: 55px;
  margin-right: 14px;

  .hamburger-1 {
    width: 50%;
  }

  .hamburger-3 {
    width: 75%;
  }

  span {
    background-color: #fff;
    border-radius: 10px;
    height: 2px;
    margin: 2px 0;
    transition: var(--el-transition-all);
    width: 100%;
  }
}

.bottom-tab {
  width: 100%;
  z-index: 99;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgb(7, 48, 158);
  // padding: .5rem;
  color: #fff;
  // height: 55px;
  

  .tabBar {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    backdrop-filter: none;

    .bg-blue{
      background-color: rgb(7, 48, 158);
    }

    .bg-register{
      background-color: #00b2ff
    }

    .tab-item {
      -webkit-flex-direction: column;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 1rem 0.5rem;
      // padding-bottom: .5rem;
      text-align: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;

      -webkit-box-align: center;
      -webkit-align-items: center;

      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      text-align: center;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        width: 2px;
        top: 50%;
        transform: translateY(-50%);
        right: -1px;
        height: 25px;
        background-color: #fff;
        border-radius: 10px;
        transition: .3s;
      }
    }

    .no-tab-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 1rem 0.5rem;
      text-align: center;
      text-align: center;
      position: relative;
    }
  }
}
</style>
