import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import messages from './i18n/index.js'
import { createI18n } from 'vue-i18n'
import storage from './utils/storage'
import api from './utils/api'
import {formatDate } from './utils/date-format'

console.log("messages:", messages)
const i18n = createI18n({
    legacy: true,
    globalInjection: true,
    locale: 'my', // set default locale,
    messages: messages,
    fallbackWarn: false
})

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


app.config.globalProperties.$api = api;
app.config.globalProperties.$formatDate = formatDate;

app.use(storage)


app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')
