<template>
    <div v-if="visible" class="global-dialog" @touchmove.prevent @click="close">
        <div @click.stop="" class="dialog-content" role="dialog" :style="styles">
            <div class="img-col">
                <img style="width: 100%;height: 100%;" src="@/assets/images/login-bg.webp"></img>
            </div>
            <el-row>
                <el-col :span="12" :xs="0">
                    <img style="width: 100%;height: 100%;" src="@/assets/images/login-bg.webp"></img>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="right">
                        <div class="top-box">
                            <div class="icon-close" @click="close">
                                <el-icon>
                                    <CloseBold />
                                </el-icon>
                            </div>
                        </div>
                        <div class="center-box">
                            <Login @login="login" v-if="dialogType == 'login'"></Login>
                            <Register @register="register" v-else-if="dialogType == 'register'"></Register>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <ConfirmDialog ref="confirmDialog" @confirm="onConfirm"></ConfirmDialog>
    </div>
</template>
<script>

import ConfirmDialog from './form/ConfirmDialog.vue';
import Login from './Login.vue';
import Register from './Register.vue';
import { formatString } from '@/utils/format';
const getVal = (val) => {
    const reg = /^[0-9]*$/g
    return (typeof val === 'number' || reg.test(val)) ? val + 'px' : val;
}
import { ElMessage, ElMessageBox } from 'element-plus'

import { mapMutations } from "vuex";

export default {
    name: 'GlobalDialog',
    components: {
        Login,
        Register,
        ConfirmDialog
    },
    props: {

        // 宽度设置
        // width: {
        //     type: [String, Number],
        //     default: '70%'
        // },
        // height: {
        //     type: [String, Number],
        //     default: '90%'
        // },
        // 是否显示右上角的关闭按钮
        showClose: {
            type: Boolean,
            default: true
        },

        // 关闭按钮前的事件处理
        beforeClose: {
            type: Function,
            default: () => {
                return true;
            }
        }
    },
    computed: {
        styles() {
            return {
                // height: getVal(this.height),
                width: getVal(this.width)
            }
        },

    },
    data() {
        return {
            dialogType: "register",
            // 弹框的显示隐藏
            visible: false,
            width: '70%'
        }
    },
    mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize)
    },
    onUnmounted() {
        document.body.style.overflow = '';
    },
    methods: {
        ...mapMutations("user", ["setUserInfo", "setToken", "setTokenExpiration"]),
        show(type) {
            this.dialogType = type || "login"
            this.visible = true
            document.body.style.overflow = 'hidden';
        },
        close() {
            this.visible = false
            document.body.style.overflow = '';
        },
        register(data) {
            // delete data.confirmPwd;
            console.log("register data:", data)
            this.$api.post("/register", data).then(res => {
                console.log("register res:", res)
                if (res.code == 0) {
                    this.$refs.confirmDialog.open({
                        icon:"success",
                        tip: this.$t('components.ComfirmDialog.registerSuccess')
                    })
                    // this.dialogType = "login"
                }else if(res.code == 100) {
                    this.$refs.confirmDialog.open({
                        icon:"error",
                        tip: formatString(this.$t('register.tips.accountExist'), data.mobile) 
                    })
                }
                else {
                    this.$refs.confirmDialog.open({
                        icon:"error",
                        tip: this.$t('components.ComfirmDialog.registerFailed')
                    })
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        login(data) {
            this.$api.post("/login/mobile", data).then(res => {
                console.log("login res:", res)
                if (res.code == 0) {
                    this.$refs.confirmDialog.open({
                        icon:"success",
                        tip:this.$t('components.ComfirmDialog.loginSuccess')
                    })
                   
                    //缓存token数据
                    let data = res.data
                    this.setToken(data.token)
                    this.setTokenExpiration(data.token_expired)
                    this.setUserInfo(data.user)
                } else {
                    this.$refs.confirmDialog.open({
                        icon:"error",
                        tip: this.$t('components.ComfirmDialog.loginFailed')
                    })
                }
            })
        },
        handleResize() {
            let innerWidth = window.innerWidth;
            if (innerWidth > 798) {
                this.width = "70%"
            } else {
                this.width = "90%"
            }
        },
        onConfirm(){
            this.close()
        }
    }
};
</script>
<style lang="scss" scoped>
.global-dialog {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-content {
    background-color: #f5f5f5;
    //   padding: 20px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    // min-width: 1000px;
}

.img-col {
    display: none;
    opacity: 0.5;
    width: 100%;
    height: 100%;
}

@media (max-width: 798px) {
    .img-col {
        display: inline;
        position: absolute;
    }
}

.model-body {
    width: 100%;
    // height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.top-box {
    height: 40px;
    position: relative;
}

.icon-close {
    position: absolute;
    right: 20px;
    // top: 20px;
    line-height: 44px;
    // z-index: 1200;
    cursor: pointer;
}

.right {
    height: 100%;
    width: 100%;
    // background-color: #fff;
}

.center-box {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
}
</style>