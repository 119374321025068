<template>
    <div class="tnr-card">
        <el-row justify="end">
            <el-col :span="12" :xs="24" class="body-box">
                <div class="body">
                    <h3>{{ $t("home.tnr.title") }}</h3>
                    <p style="font-size: 14px;">{{ $t("home.tnr.desc1") }}<br>{{ $t("home.tnr.desc2") }}</p>
                    <el-button type="primary" @click="clickBtn"
                        class="btn outline-btn mt-4 ms-auto me-auto me-lg-0 px-5">{{ $t("home.tnr.butoon") }}</el-button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        clickBtn(){
            this.$emit("clickBtn")
        }
    },
}
</script>

<style lang="scss" scoped>
.container-wrapper {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}


@media (min-width: 992px) {
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}


.tnr-card {
    background-image: url('@/assets/images/tnr.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    // width: 100%;
    height: 300px;
    color: #fff;
    border-radius: 20px;
    margin: 0 20px;

    .btn.outline-btn {
        border: 2px solid #fff !important;
        padding-right: 3rem !important;
        padding-left: 3rem !important;
        height: 40px;
        border-radius: 10px;
    }

    .body-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        width: 100%;
        padding: 3rem !important;
        text-align: right;
    }
}
</style>