<template>
    <el-dialog v-model="dialogVisible" @close="onClose" :close-on-click-modal="false" :center="true" :width="width">
        <slot>
            <div class="content-body">
                <div v-if="icon == 'success'">
                    <el-icon color="#67C23A" size="100">
                        <CircleCheck />
                    </el-icon>
                </div>
                <div v-else-if="icon == 'error'">
                    <el-icon color="#F56C6C" size="100">
                        <CircleClose />
                    </el-icon>
                </div>
                <div class="tip">{{ tip }}</div>
            </div>
        </slot>
        <span slot="footer" class="footer">
            <el-button class="btn"
                type="primary" @click="onConfirm">{{ $t('components.ComfirmDialog.Ok') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        // icon: {
        //     type: String,
        //     default: ""
        // },
        // tip: {
        //     type: String,
        //     default: ""
        // }
    },

    data() {
        return {
            dialogVisible: false,
            width: "400px",
            icon:"success",
            tip:""
        }
    },
    mounted() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize)
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
            this.dialogVisible = false;
        },
        onClose() {
            this.$emit('close');
        },
        open(data) {
            console.log("open==========")
            this.icon = data.icon
            this.tip = data.tip
            this.dialogVisible = true;
        },
        handleResize() {
            let innerWidth = window.innerWidth;
            if (innerWidth > 798) {
                this.width = "400px"
            } else {
                this.width = "90%"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.content-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;

    .tip {
        color: #000000;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

}

.footer {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
        width: 120px;
        background-color: #07309e;
        border: none;
        height: 40px;
    }
}
</style>