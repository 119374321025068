<template>
  <el-config-provider :locale="elLocale">
    <el-container>
      <el-header>
        <navbar />
      </el-header>
      <el-main> <app-main /></el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
      <div class="bottom-place"></div>
    </el-container>

    <!-- <div class="app-wrapper">
      <div class="main-container">
        <div class="fixed-header">
          <navbar />
        </div>
        <app-main />
      </div>
      <Footer></Footer>
    </div> -->
  </el-config-provider>
</template>

<script>
import { AppMain, Navbar, } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import Footer from '@/components/Footer.vue';
// import { ElConfigProvider } from 'element-plus'
import zh from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import vi from 'element-plus/es/locale/lang/vi'
import th from 'element-plus/es/locale/lang/th'
import id from 'element-plus/es/locale/lang/id'

import { mapState } from "vuex";

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Footer
  },
  mixins: [ResizeMixin],
  data() {
    return {
    }
  },
  computed: {
    ...mapState("app", ["locale"]),
    elLocale() {
      let _local_locale = {
        en,
        "zh-cn": zh,
        th,
        vi,
        id,
        ph: en,
        my: en,
        sg: en
      }

      return _local_locale[this.locale]
    }
  },
  methods: {
    handleClickOutside() {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.el-header{
  padding: 0;
}

.el-main{
  padding: 0;
}

.app-wrapper {
  @include clearfix;
  // position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px)
}

.mobile .fixed-header {
  width: 100%;
}

@media (max-width: 992px) {
  .bottom-place {
    height: 55px;
  }
}
</style>