<template>
  <div class="home">
    <el-carousel :interval="5000" arrow="always" :height="bannerHeight">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <el-image style="width: 100%; height: 100%;object-fit: cover;" :src="item.img" fit="fill" />
      </el-carousel-item>
    </el-carousel>
    <lenders></lenders>
    <!-- 底部轮播 -->
    <!-- <ScrollSeamless></ScrollSeamless> -->
  </div>
</template>

<script>

import GlobalDialog from '@/components/GlobalDialog.vue';
import lenders from '@/components/lenders'
import ScrollSeamless from '@/components/ScrollSeamless'

export default {
  name: 'HomeView',
  components: {
    lenders,
    GlobalDialog,
    ScrollSeamless
  },
  data() {
    return {
      bannerList: [{
        img: require("@/assets/images/banner/banner1.jpg")
      },
      {
        img: require("@/assets/images/banner/banner2.jpg")
      }
      ],
      bannerHeight: "375px",
    }
  },
  mounted() {
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      let innerWidth = window.innerWidth;
      if (innerWidth > 576) {
        this.bannerHeight = "375px"
      } else {
        this.bannerHeight = "180px"
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
}

::v-deep .el-carousel__arrow {
  z-index: 1;
}

.carousel {
  height: 365px;
}

@media (max-width: 576px) {
  .carousel {
    height: 180px;
  }

}
</style>
