import storage from "@/utils/storage"

export default {
    namespaced: true,
    state: {
        locale: storage.get("locale") || "my",
        mobileList: [],
    },
    mutations: {
        setLocale(state, locale) {
            state.locale = locale
            storage.set("locale", locale)
        },
        setMobileList(state, list) {
            console.log("setMobileList:", list)
            state.mobileList = list
            storage.set("mobileList", list)
        }
    }
}