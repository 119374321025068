<template>
    <div class="applicant-card">
        <div class="body">
            <div class="top">
                <div>
                    <p>{{ $t("home.applicantes.card.TotalApplicants") }}</p>
                    <h5>{{ applicantTotal.toLocaleString() }}</h5>
                </div>
                <el-image class="flag" :src="img"></el-image>
            </div>
            <div class="dark my-3"></div>
            <div class="bottom">
                <p>{{ $t("home.applicantes.card.TotalLoan") }}</p>
                <h5>{{ currencyCode + ' ' + loanTotal.toLocaleString() }} </h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        applicantTotal: {
            type: Number,
            default: 0
        },
        loanTotal: {
            type: Number,
            default: 0
        },
        loanTotal: {
            type: Number,
            default: 0
        },
        currencyCode: {
            type: String,
            default: ""
        },
        img: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="scss" scoped>
.applicant-card {
    background: rgba(255, 255, 255, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    flex-direction: column;
    padding: 1.5rem;

    .body {
        // padding: 10px;
        display: flex;
        flex-direction: column;
        color: rgb(33, 37, 41);
        width: 100%;
        // padding: 1.5rem;

        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    p {
        font-size: 17px;
        margin-bottom: 3px;
        margin-top: 0px;

    }

    h5 {
        margin-bottom: 0;
        font-size: 22px;
        line-height: 22px;
        margin-top: 0px;
    }

    .flag {
        width: 40px;
        height: 40px;
        box-shadow: 0 3px 5px #00000040;
        border-radius: 50%;
    }

    .dark {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    .my-3 {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}

::v-deep{
    --el-border-style{
      color: rgba(0, 0, 0, .1);
    }
}

</style>