<template>
  <section class="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <keep-alive >
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  // overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 70px;
}
</style>
