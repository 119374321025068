<template>
    <div class="scroll-card">
        <div v-if="list.length > 0">
            <vue3ScrollSeamless class="scroll-wrap" :classOptions="classOptions" :dataList="list">
                <ul class="ui-wrap">
                    <li class="li-item" v-for="(item, i) of list" :key="i">
                        <!-- //xxx 时间 申请了iphone -->
                        <p>{{ `${item.user_mobile} ${$formatDate(item.created)} ${$t('home.scrollList.apply')} ${item.mobile_model_name} ` }}</p>
                    </li>
                </ul>
            </vue3ScrollSeamless>
        </div>
    </div>
</template>

<script>
import { vue3ScrollSeamless } from "vue3-scroll-seamless";
import { getScrollList } from '@/api/lenders'
export default {
    components: {
        vue3ScrollSeamless
    },
    data() {
        return {
            list: [],
            classOptions: {
                limitMoveNum: 4
            }
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            getScrollList().then(res => {
                if (res.code == 0) {
                    console.log("getScrollList res:", res)
                    this.list = res.data
                }
            })
        }
    },

}
</script>

<style lang="scss" scoped>
.scroll-card {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    margin: 20px;
}

.demo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-wrap {
    height: 200px;
    width: 600px;
    margin: 0 auto;
    overflow: hidden;
}

.ui-wrap {
    list-style: none;
    padding: 0;
    margin: 0 auto;
}

.li-item {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
}
</style>