import storage from "@/utils/storage"

export default {
  namespaced: true,
  state: {
    token: storage.get("token") || "",
    tokenExpiration: storage.get("tokenExpiration") || 0,
    userInfo: storage.get("userInfo") || {},
  },
  getters: {
    hasLogin: (state) => {
      // console.log("getTime:", new Date().getTime())
      return state.userInfo && state.tokenExpiration > new Date().getTime() / 1000
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      // console.log("setToken:==========", token)
      storage.set("token", token)
    },
    setTokenExpiration(state, tokenExpiration) {
      state.tokenExpiration = tokenExpiration
      storage.set("tokenExpiration", tokenExpiration)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      storage.set("userInfo", userInfo)
    }
  },
  actions: {
    logout({ commit }) {
      commit('setToken')
      commit('setTokenExpiration')
      commit('setUserInfo', {})
    },
    login({ commit }, userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password }).then(response => {
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // get user info
    getInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(response => {
          const { data } = response

          if (!data) {
            reject('Verification failed, please Login again.')
          }

          const { roles, name, avatar, introduction } = data

          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('getInfo: roles must be a non-null array!')
          }

          commit('SET_ROLES', roles)
          commit('SET_NAME', name)
          commit('SET_AVATAR', avatar)
          commit('SET_INTRODUCTION', introduction)
          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }


}