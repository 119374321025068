<template>
    <div class="login-box">
        <div class="title">
            <h4>{{ $t("login.text.title") }}</h4>
        </div>
        <el-form ref="loginForm" :model="formData" :rules="rules" label-width="auto" style="max-width: 600px">
            <el-form-item prop="mobile">
                <el-input size="large" class="form-input" v-model="formData.mobile"
                    :placeholder="$t('login.field.mobile')" />
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" show-password size="large" class="form-input" v-model="formData.password"
                    :placeholder="$t('login.field.password')" />
            </el-form-item>
            <el-button style="width: 100%;height: 40px;" color="#07309E" type="primary" @click="submitForm">{{
                $t("login.button.login") }}</el-button>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {

        let formData = {
            "mobile": "",
            "password": "",
        }
        return {
            formData,
            rules: {
                username: [
                    { required: true, message: '', trigger: 'blur' }
                ],
                password: [
                    { required: true, min: 6, message: '', trigger: 'blur' }
                ]
            }

        }
    },
    methods: {
        submitForm() {
            if (this.$refs.loginForm) {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        // 提交表单逻辑
                        this.$emit("login", this.formData)
                    } else {
                        console.log('验证失败');
                        // ElMessage.error('验证失败，请检查您的输入！');
                    }
                });
            } else {
                console.error('表单未找到');
            }
        }
    },
}
</script>

<style lang="scss" scoped>

.login-box {
    width: 100%;
    padding: 0 1.5rem 2rem 1.5rem;
}

.title {
    margin-bottom: 1rem !important;
    display: flex;
    justify-content: center;

    h4 {
        font-weight: 500;
        color: #07309e;
        font-size: calc(1rem + .3vw);
    }
}

.form-input {
    // margin-top: 10px ;
    // margin-bottom: 10px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #F5F5F5;
    // padding: 10px 15px;
}
</style>