import axios from 'axios'
import { ElMessage, MessageBox } from 'element-plus'
import store from '@/store'
import router from '@/router'

import config from '@/config'

export const baseURL = config.serverAddr || "";


console.log("VUE_APP_BASE_API:", baseURL)
// create an axios instance
const apiClient = axios.create({
  baseURL,
  timeout: 5000
})

const ignoreTokenUrls = ["/login", "/register", "/mobile_list", "/calc_lender", "/config"]

// request interceptor
apiClient.interceptors.request.use(
  config => {

    // console.log("config:", config)
    if (!ignoreTokenUrls.includes(config.url)) {
      config.headers.Authorization = `Bearer ${store.state.user.token}`
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
apiClient.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  async error => {
    console.log('response =========  err' + error) // for debug


    if (error.response) {
      // 判断状态码
      switch (error.response.status) {
        case 401:
          router.replace("/")
          store.commit('user/setTokenExpiration', 0);
          break;
        default:
          console.log(error.response.data);
          break;
      }
    } 

    // 服务器挂掉处理逻辑
    if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      console.error('服务器响应超时，请检查服务器状态！');
      // 可以进行重试逻辑，或者其他错误处理
    } else {
      console.error('其他错误：', error);
      // 其他错误处理逻辑
    }
    return Promise.reject(error);
  }
)

export default {
  get(path, params) {
    return apiClient.get(path, { params });
  },
  post(path, data) {
    return apiClient.post(path, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  },
  put(path, data) {
    return apiClient.put(path, data);
  },
  delete(path) {
    return apiClient.delete(path);
  },
};
