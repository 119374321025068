<template>
    <div class="register-box">
        <div class="title flex-justify-center">
            <h4> {{ $t("register.title") }}</h4>
        </div>
        <el-form ref="ruleFormRef" :rules="rules" :model="formData" label-width="auto" style="max-width: 600px">
            <el-form-item prop="nickname">
                <el-input size="large" class="form-input" v-model="formData.nickname"
                    :placeholder="$t('register.nickname')" />
            </el-form-item>
            <el-form-item prop="mobile">
                <el-input size="large" class="form-input" v-model="formData.mobile"
                    :placeholder="$t('register.mobile')" />
            </el-form-item>
            <el-form-item prop="birthday">
                <el-date-picker value-format="YYYY-MM-DD" style="width: 100%;" size="large" class="form-input" v-model="formData.birthday"
                    type="date" :placeholder="$t('register.birthday_label')" />
            </el-form-item>
            <el-form-item prop="password">
                <el-input type="password" show-password size="large" class="form-input" v-model="formData.password"
                    :placeholder="$t('register.password')" />
            </el-form-item>
            <el-form-item prop="confirmPwd">
                <el-input type="password" show-password size="large" class="form-input" v-model="formData.confirmPwd"
                    :placeholder="$t('register.confirm_password')" />
            </el-form-item>
            <el-form-item prop="inviteCode">
                <el-input size="large" class="form-input" v-model="formData.inviteCode"
                    :placeholder="$t('register.inviteCode')" />
            </el-form-item>
            <el-button  style="width: 100%;height: 40px;"  @click="submit">{{ $t("register.button") }}</el-button>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Register",
    data() {
        let formData = {
            "nickname": "",
            "mobile": "",
            "password": "",
            "confirmPwd": "",
            "birthday": "",
            "inviteCode": "",
        }

        return {
            formData,
            rules: {
                nickname: [
                    { required: true, message: '', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '', trigger: 'blur' },
                    { min: 6, message: '', trigger: 'blur' }
                ],
                confirmPwd: [
                    { required: true, message: '', trigger: 'blur' }
                ],
                birthday: [
                    {
                        type: 'date',
                        required: true,
                        message: 'Please pick a time',
                        trigger: 'change',
                    },
                ]
            }

        }
    },
    methods: {
        submit() {
            this.$refs.ruleFormRef.validate((valid) => {
                if (valid) {
                    console.log('submit!', this.formData)
                    this.$emit("register", this.formData)

                } else {
                    console.log('error submit!')
                }
            })
        }
    },
}
</script>

<style lang="scss" scoped>

.register-box {
    width: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.title {
    margin-bottom: 1rem !important;

    h4 {
        font-weight: 500;
        color: #07309e;
        font-size: calc(1rem + .3vw);
    }
}

.form-input {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #F5F5F5;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}
</style>