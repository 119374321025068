// storage.js

export const getLocalStorage = (key, defaultValue = null) => {
    const item = localStorage.getItem(key);
    if (item) {
        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }
    }
    return defaultValue;
};

export const setLocalStorage = (key, value) => {
    if (value) {
        localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
    } else {
        localStorage.removeItem(key);
    }
};

export default {
    // 获取localStorage数据
    get(key) {
        const value = localStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            return value;
        }
    },
    // 设置localStorage数据
    set(key, value) {
        if (value) {
            localStorage.setItem(key, typeof value === 'string' ? value : JSON.stringify(value));
        } else {
            localStorage.removeItem(key);
        }
    },
    // 移除localStorage数据
    remove(key) {
        localStorage.removeItem(key);
    },
    // 清空localStorage
    clear() {
        localStorage.clear();
    }
};