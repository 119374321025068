<template>
      <el-dropdown style="margin-left: 5px;">
            <span class="info-tooltip ms-2">
                ?
            </span>
            <template #dropdown>
                <div class="popover-body">
                    <div>
                        <h6 class="fw-bold mb-3">{{ $t('components.PopoverBatteryCheck.title')}}</h6>
                        <p class="mb-1">{{ $t('components.PopoverBatteryCheck.desc1')}} &gt;
                             {{ $t('components.PopoverBatteryCheck.desc2')}} &gt; {{ $t('components.PopoverBatteryCheck.desc3')}}</p>
                        <p> {{ $t('components.PopoverBatteryCheck.desc4')}}</p>
                        <img src="@/assets/images/batteryHealth.gif" alt="Check Battery Health On iPhone" class="w-100">
                    </div>
                </div>
            </template>
        </el-dropdown>
</template>

<script>
export default {
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.info-tooltip {
    min-width: 22px !important;
    height: 22px !important;
    background-color: #07309e;
    color: #fff;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.popover-body {
    padding: 1rem;
    color: #212529;
    max-width: 276px;

    .fw-bold {
        font-weight: 700;
    }

    .mb-3 {
        margin-bottom: 1rem;
    }

    .mb-1 {
        margin-bottom: .25rem;
    }

    p {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h6 {
    font-size: 1rem;
}
</style>