import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'

/* Layout */
import Layout from '@/layout'

const routes = [
  {
    path: '/',

    component: Layout,
    children: [{
      path: '',
      name: 'home',
      component: HomeView
    },
    {
      path: '/info',
      name: 'info',
      component: () => import('../views/info')
    },
    {
      path: '/InstantApply',
      name: 'InstantApply',
      meta:{
        requireAuth:true
      },
      component: () => import('../views/InstantApply')
    },
    {
      path: '/LoanRepayment',
      name: 'LoanRepayment',
      meta:{
        requireAuth:true
      },
      component: () => import('../views/LoanRepayment')
    },
    {
      path: '/profile',
      name: 'Profile',
      meta:{
        requireAuth:true
      },
      component: () => import('../views/user/profile')
    }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})

router.beforeEach(async (to, from) => {
  const hasLogin = store.getters["user/hasLogin"]

  if (to.meta.requireAuth && !hasLogin) {
    return "/"
  }
})

router.afterEach(() => {
})


export default router
